import { useMutation } from '@apollo/client';
import { Button, Chip, Icon, IconButton, TextField, Typography } from '@material-ui/core';
import { CATALOG_TYPE, Store } from '@risk-and-safety/library';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import BarcodeInput from './BarcodeInput';
import StoreLocations from './StoreLocations';
import { ADD_PRODUCT_ITEMS } from '../graphql/query';
import { ConfirmationDialog, ButtonProgress } from '../../components';
import { invalidBarcodeOverride } from '../helper/order.helper';

function ProductStockAdd({ product, store: { campusCode, inventory } }) {
  const { productId, storeId } = useParams();
  const history = useHistory();
  const [barcodes, setBarcodes] = useState([]);
  const [location, setLocation] = useState(null);
  const [addItemNoBarcode, setaddItemNoBarcode] = useState(false);
  const [invalidPatternBarcodes, setInvalidPatternBarcodes] = useState([]);
  const [invalidDuplicateBarcodes, setInvalidDuplicateBarcodes] = useState([]);
  const barcodeNeeded = product?.barcodeRequired;
  const [numItems, setNumItems] = useState(parseInt(0));
  const navigateBack = () => history.push(`/store/${storeId}/product/${productId}/stock`);

  const [addProductStock, { loading }] = useMutation(ADD_PRODUCT_ITEMS, {
    onCompleted: navigateBack,
  });
  return (
    <>
      <div className="absolute left-48 top-136 flex h-64 items-center">
        <IconButton aria-label="back" onClick={navigateBack}>
          <Icon>arrow_back</Icon>
        </IconButton>
      </div>
      {barcodeNeeded ? (
        <div className="absolute right-48 top-136 flex h-64 items-center">
          <ButtonProgress
            disabled={!barcodes.length}
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              addProductStock({
                variables: {
                  productId,
                  storeId,
                  barcodes,
                  sublocationId: location.sublocationId,
                  quantity: parseInt(barcodes.length),
                },
              });
            }}>
            Save
          </ButtonProgress>
        </div>
      ) : (
        <div className="absolute right-48 top-136 flex h-64 items-center">
          <ButtonProgress
            disabled={numItems <= 0 || numItems > 999 || !location}
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              setaddItemNoBarcode(true);
            }}>
            Add items
          </ButtonProgress>
          <ConfirmationDialog
            open={addItemNoBarcode}
            addItemNoBarcode
            title="Add Item"
            primaryAction="Confirm"
            message={`Are you sure you want to add items without a barcode?`}
            onClose={(confirmed) => {
              if (confirmed) {
                addProductStock({
                  variables: {
                    productId,
                    storeId,
                    barcodes,
                    sublocationId: location.sublocationId,
                    quantity: parseInt(numItems),
                  },
                });
              }
              setaddItemNoBarcode(false);
            }}
          />
        </div>
      )}

      {!Object.keys(inventory?.sublocations || {})?.length ? (
        <div className="grid justify-items-center">
          <Typography className="my-16">Store sublocations must be set up before you can add product stock.</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(`/store/${storeId}/settings/location`)}>
            Add Sublocations
          </Button>
        </div>
      ) : (
        <>
          <div className="py-16">
            <Chip size="small" label={1} color="primary" className="mr-14" />
            <Typography className="inline">Select a sublocation to save new stock to</Typography>
          </div>

          <div className="pl-32">
            <StoreLocations sublocations={inventory?.sublocations} setLocation={(loc) => setLocation(loc)} />
          </div>

          {barcodeNeeded ? (
            <>
              <div className="relative pb-16 pt-40">
                <Chip size="small" label={2} color="primary" className="mr-14" />
                <Typography className="inline">Scan each newly barcoded item </Typography>
                {Boolean(barcodes.length) && (
                  <div className="absolute right-4 top-28 inline items-center">
                    <Typography color="primary" className="inline pr-12 text-28">
                      {barcodes.length}
                    </Typography>
                    <Typography color="primary" className="inline text-16">
                      items scanned
                    </Typography>
                  </div>
                )}
              </div>
              <div className="pl-32">
                <BarcodeInput
                  campusCode={campusCode}
                  placeholder="Scan barcodes continously"
                  onValidBarcode={(validBarcode) => setBarcodes([...new Set([...barcodes, validBarcode])])}
                  onInvalidDuplicateBarcode={(invalidBarcode) =>
                    setInvalidDuplicateBarcodes([...new Set([...invalidDuplicateBarcodes, invalidBarcode])])
                  }
                  onInvalidPatternBarcode={(invalidBarcode) =>
                    invalidBarcodeOverride(invalidBarcode, product) ? setBarcodes([...new Set([...barcodes, invalidBarcode])] ) : setInvalidPatternBarcodes([...new Set([...invalidPatternBarcodes, invalidBarcode])])
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div className="relative pb-6 pt-40">
                <Chip size="small" label={2} color="primary" className="mr-14" />
                <Typography className="inline">Indicate the number of items you want to add </Typography>
              </div>
              <TextField
                className="mb-8 ml-32 mt-8 w-1/4"
                id="quantity"
                aria-labelledby="quantity"
                error={numItems <= 0 || numItems > 999}
                helperText={numItems <= 0 || numItems > 999 ? 'Range: 1-999' : ''}
                value={numItems}
                onChange={(event) => {
                  setNumItems(parseInt(event.target.value));
                }}
                label="Quantity"
                InputProps={{
                  inputProps: { min: 0, max: 999 },
                }}
                type="number"
                variant="outlined"
              />
            </>
          )}

          <div className="px-32">
            {barcodes.map((b) => (
              <Chip
                key={b}
                label={b}
                className="mb-6 mr-6"
                onDelete={() => setBarcodes(barcodes.filter((barcode) => barcode !== b))}
              />
            ))}
          </div>

          {Boolean(invalidPatternBarcodes.length) && (
            <>
              <div className="px-32 pb-8 pt-16">Invalid Barcode Pattern:</div>

              {invalidPatternBarcodes.map((b) => (
                <div key={b} className="mr-6 px-32 text-error-500">
                  {b}
                </div>
              ))}
            </>
          )}
          {Boolean(invalidDuplicateBarcodes.length) && (
            <>
              <div className="px-32 pb-8 pt-16">Barcodes already in use:</div>

              {invalidDuplicateBarcodes.map((b) => (
                <div key={b} className="mr-6 px-32 text-error-500">
                  {b}
                </div>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
}

ProductStockAdd.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default ProductStockAdd;
